.App {
  text-align: center;
}

.flexbox-demo {
  background-color: var(--surface-e);
  text-align: center;
  border-radius: 4px;
  padding: 1rem;
}

.layout-content {
  padding: 2rem;
}

.content-section {
  background-color: var(--surface-b);
}

.p-grid .layout-sidebar.p-col-fixed {
  width: 14vw;
  background-color: var(--surface-a);
}

.layout-sidebar button {
  margin-left: 2em;
}

.o-table-row {
  cursor: pointer;
}